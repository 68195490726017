//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import copy from 'copy-to-clipboard';
import StepLayout from './step-layout.vue';
import Button from '../components/button.vue';
import CustomRate from '../components/custom-rate.vue';
import PowerBox from '../components/power-box.vue';
import BottomSheetCustomRate from '../components/bottom-sheet-custom-rate.vue';
import CustomOfferDisclaimer from '../components/custom-offer-disclaimer.vue';
import stepMixin from '../lib/step-mixin.js';
import { changeElectricalPower } from '../lib/api.js';

export default {
  components: {
    PowerBox,
    CustomRate,
    StepLayout,
    Button,
    BottomSheetCustomRate,
    CustomOfferDisclaimer
  },
  mixins: [stepMixin],
  props: {
    offers: Object,
    leadId: String,
    channel: String,
    selectedRates: Array
  },
  data: () => ({
    selectedOffer: null,
    loading: false,
    downloading: false
  }),
  computed: {
    offersList() {
      return this.offers?.external?.ofertas || [];
    },
    selectedOfferObj() {
      return this.offers.external.ofertas?.find(offer => offer.id === this.selectedOffer);
    },
    hiredPower() {
      const list = this.offers?.external?.datosEstudio?.potenciaContratada;
      const keys = Object.keys(list);

      return keys
        .reduce((acc, key) => {
          const value = list[key];

          if (Number(value) === 0) return acc;

          acc.push(`${key.toUpperCase()}: ${value} kW`);

          return acc;
        }, [])
        .join(', ');
    }
  },
  created() {
    this.selectedOffer =
      this.offers?.selected?.offerSelected?.id || this.offers.external.ofertas[0].id;
  },
  methods: {
    handleDownload() {
      this.downloading = true;
      this.$emit('download-offer', {
        offerId: this.selectedOffer,
        stopLoading: () => {
          this.downloading = false;
        }
      });
    },
    share() {
      const url = window.location.href;
      const data = {
        title: 'Oferta de suministro',
        text: 'Oferta de suministro',
        url
      };

      if (navigator.share !== undefined) {
        navigator.share(data);
      } else {
        copy(`${data.title}: ${data.url}`, {
          message: 'Pulsa #{key} para copiar'
        });
        this.$notify.success('¡Oferta copiada en el portapapeles!');
      }
    },
    onOfferSelected(id) {
      this.selectedOffer = id;
    },
    isAgent() {
      const channel = window.localStorage.getItem('channel');

      return channel === this.channel;
    },
    isAutomaticChannel() {
      const bool = this.channel === 'automatic';
      return bool;
    },
    onContinue() {
      this.loading = true;
      this.$emit('continue', {
        code: this.offers.external.codigo,
        fecha: this.offers.external.fecha,
        caducidad: this.offers.external.caducidad,
        datosEstudio: this.offers.external.datosEstudio,
        offerSelected: this.selectedOfferObj
      });
    },
    async cambiarPotencias(potencias) {
      try {
        await changeElectricalPower(this.leadId, potencias);
        window.location.reload();
      } catch (error) {
        this.$notify.error('Ha ocurrido un error, contacta con el administrador.');
      }
    }
  }
};
